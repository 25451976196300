import classNames from "classnames"
import React, { useMemo, useRef } from "react"

import LayoutSection from "../LayoutComponents/LayoutSection"

import { Color } from "@constants/V2/color"
import { useElementObserver } from "@hooks/V2/useElementObserver"

type Props = {
  text: string
  highlightedText?: string
}

const TypingText = ({ text, highlightedText }: Props) => {
  const layoutSectionRef = useRef<HTMLDivElement>(null)
  const { isVisible, startObserving } = useElementObserver(layoutSectionRef)

  const individualCharacters = useMemo(() => {
    const transitionDelay = 7 // in MS
    const splitTextList = text.split("")

    return [
      ...splitTextList,
      ...(highlightedText ? [" ", ...highlightedText.split("")] : []),
    ].map((char, index) => (
      <span
        key={`split-text-${index}`}
        className={classNames(
          "text-responsive-h4 font-normal opacity-20 transition-all",
          index < splitTextList.length
            ? "typing-text"
            : "highlighted-typing-text"
        )}
        style={{
          transitionDelay: `${(index + 1) * transitionDelay}ms`,
        }}
      >
        {char}
      </span>
    ))
  }, [text, highlightedText])

  if (isVisible && layoutSectionRef.current) {
    layoutSectionRef.current
      .querySelectorAll(".typing-text")
      .forEach((span) => span.classList.replace("opacity-20", "opacity-100"))

    if (highlightedText) {
      layoutSectionRef.current
        .querySelectorAll(".highlighted-typing-text")
        .forEach((span) => {
          span.classList.replace("opacity-20", "opacity-100")
          span.classList.add("text-blue")
        })
    }
  }

  startObserving()

  return (
    <LayoutSection ref={layoutSectionRef} primaryBackgroundColor={Color.White}>
      {individualCharacters}
    </LayoutSection>
  )
}

export default TypingText
