import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import TypingText from "@components/V2/Banners/TypingText"

const TypingTextBanner = ({
  blok,
}: Storyblok.BlokProps<Storyblok.TypingTextBanner>) => {
  return (
    <TypingText
      text={blok.text}
      highlightedText={blok.highlightedText}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}

export default TypingTextBanner
